import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'

import { Gem, Star } from '../../../icons'
import Checkmark from '../../../icons/Checkmark'
import Card from '../../Card'
import Text from '../../Text'

export const MEMBERSHIPS = {
  CLASSIC: 'classic',
  BASIC: 'basic'
}

const { CLASSIC, BASIC } = MEMBERSHIPS

const MembershipSelectorCard = ({ membershipCards, membershipHeader }) => {
  const membershipIcon = {
    basic: <Star />,
    classic: <Gem />
  }

  return (
    <section className='membership-selector-card__section'>
      <div className='membership-selector-card__section-wrapper'>
        <Text.Heading
          className='membership-selector-card__section-header'
          size='xlarge'>
          {membershipHeader}
        </Text.Heading>
      </div>

      <div className='membership-selector-card__container membership-selector-card__section-wrapper'>
        {membershipCards.map(({node}) => {
          const {
            id,
            planDisplayName,
            type,
            subText,
            features
          } = node

          const isBasic = BASIC === type
          const isClassic = CLASSIC === type

          return (
            <div key={id} className='membership-selector-card'>
              <div className={classnames(
                'fb-card-content__header',
                'membership-selector-card__header',
                {'membership-selector-card__header--basic': isBasic},
                {'membership-selector-card__header--classic': isClassic}
              )}>
                <div className='membership-selector-card__header-title-icon'>
                  <Text
                    className='membership-selector-card__header-icon'
                    color={isBasic ? 'brand' : 'light'}
                  >
                    {membershipIcon[type]}
                  </Text>
                  <Text className='membership-selector-card__header-title' weight='bold'>{planDisplayName}</Text>
                </div>
                <Text className='membership-selector-card__header-subtitle'>{subText}</Text>
              </div>

              <Card spread>
                <Card.Content
                  buttonLink='/signup'
                  buttonText='Sign up'
                >
                  <ul className='membership-selector-card__list-container'>
                    {features.map(feature => (
                      <li key={feature} className='membership-selector-card__list'>
                        <div className='membership-selector-card__icon-info'>
                          <Text className='membership-selector-card__icon-container' tag='span'>
                            <Checkmark className='membership-selector-card__icon' />
                          </Text>
                          <Text className='membership-selector-card__info'>{feature}</Text>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Content>
              </Card>
            </div>
          )
        })}
      </div>

      <div className='membership-selector-card__section-wrapper membership-selector-card__section-footer'>
        Not a retail pharmacy? Check out our offerings for <Text tag='span'><Link className='membership-selector-card__section-footer-link' to='#hospitals' >hospitals & clinics</Link></Text> and <Text tag='span'><Link className='membership-selector-card__section-footer-link' to='#telehealth'>insurers & telehealth providers</Link></Text>
      </div>
    </section>
  )
}

export default MembershipSelectorCard
